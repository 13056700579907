.App {
  text-align: center;
}
.header-content {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  width: 100%;
  padding: 0px 0;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  background-color: #c1999d;
  color: #fff;
}
form {
  display: inline-block;
  text-align: left;
  margin: 0px;
}

div {
  margin-bottom: 10px;
}

label {
  margin-right: 10px;
}

input,
select {
  padding: 5px;
}

button {
  padding: 5px 10px;
  cursor: pointer;
}
header {
  background-color: #333;
  color: white;
  padding: 10px 0;
}

/* navigation */
.navigation {
  background-color: #b16767;
  padding: 10px 0;
}

.navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center; /* Center the navigation links */
}

.navigation li {
  margin-right: 10px;
}

.navigation a {
  text-decoration: none;
  color: #fff;
  padding: 8px 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navigation a:hover {
  background-color: #ba1d2a;
}

.page-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2ececeb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.page-container-form {
  margin: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2ececeb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.icon {
  width: 48px; /* Adjust width as needed */
  height: 48px; /* Adjust height as needed */
  margin-right: 10px; /* Add spacing between icon and title */
  border-radius: 15px;
}

/* EULA */
.eula-container {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  text-align: left;
}

.eula-container h1,
.eula-container h2 {
  color: #333;
}

.eula-container p,
.eula-container ul {
  margin-bottom: 15px;
  color: #555;
}

.eula-container ul {
  list-style-type: disc;
  padding-left: 20px;
}
/* privacy */
.privacy-policy-container {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  text-align: left;
}

.privacy-policy-container h1,
.privacy-policy-container h2 {
  color: #333;
}

.privacy-policy-container p,
.privacy-policy-container ul {
  margin-bottom: 15px;
  color: #555;
}

.privacy-policy-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

/* Disclaimer */
.disclaimer-container {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  text-align: left;
}

.disclaimer-container h1 {
  color: #333;
}

.disclaimer-container p {
  margin-bottom: 15px;
  color: #555;
}

.disclaimer-container p strong {
  color: #000;
}

/* modal */
.modal {
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2ececeb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.error-text {
  color: red;
  font-weight: bold;
}
